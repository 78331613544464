<template>
  <div class="app-bar-box">
    <div class="banner-pledge-box text-unselect cursor" @click="goToPage">
      <div class="banner-title">
        <img class="logo-ton" src="/static/image/logo_ton.png" />
        Go to TON stake
        <v-icon size="24" color="#ffa70b" class="banner-title-arrow"
          >mdi-arrow-right-circle</v-icon
        >
      </div>
    </div>
    <v-tabs
      v-model="tab"
      fixed-tabs
      @change="changeTab"
      v-if="$route.name === 'Personal' && isMobile"
    >
      <v-tabs-slider color="yellow"></v-tabs-slider>
      <v-tab v-for="item in items" :key="item">
        {{ item }}
      </v-tab>
    </v-tabs>
    <section
      :class="[
        'app-bar-main',
        { 'app-bar-mobile': isMobile },
        { 'app-bar-web': !isMobile },
      ]"
    >
      <v-tabs
        v-model="tab"
        fixed-tabs
        @change="changeTab"
        v-if="$route.name === 'Personal' && !isMobile"
      >
        <v-tabs-slider color="yellow"></v-tabs-slider>
        <v-tab v-for="item in items" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>
      <router-view />
    </section>
    <v-navigation-drawer
      :width="getDrawerWidth"
      v-model="showDrawer"
      :absolute="isMobile"
      :temporary="isMobile"
      :permanent="!isMobile"
    >
      <nav-drawer></nav-drawer>
    </v-navigation-drawer>
  </div>
</template>

<script>
import navDrawer from "@/components/NavDrawer";
import { mapState } from "vuex";
export default {
  name: "Layout",
  components: {
    navDrawer,
  },
  data() {
    return {
      showDrawer: false,
      tab: 0,
      items: [this.$t("navDrawer.invitation"), this.$t("navDrawer.placement")],
    };
  },
  watch: {
    showDrawer: {
      handler(newVal, oldVal) {
        this.$store.commit("system/isShowNavDrawer", newVal);
      },
    },
    isShowNavDrawer: {
      handler(newVal, oldVal) {
        this.showDrawer = newVal;
      },
    },
  },
  computed: {
    ...mapState({
      isShowNavDrawer: (state) => state.system.isShowNavDrawer,
    }),
    getDrawerWidth() {
      return this.isMobile ? "85%" : "320px";
    },
    isMobile() {
      console.log(
        "isMobile",
        this.$vuetify.breakpoint,
        this.$vuetify.breakpoint.mobile
      );
      return this.$vuetify.breakpoint.mobile;
    },
  },
  created() {
    this.showDrawer = this.isShowNavDrawer;
  },
  methods: {
    goToPage() {
      window.location.href = "https://stake.bidex.pro";
    },
    changeTab(tab) {
      this.$store.commit("user/setCurrentNavBarTab", tab);
    },
  },
};
</script>

<style scoped lang="scss">
.app-bar-box {
  height: 100%;
  overflow: hidden;
}

.app-bar-main {
  height: 100%;
  overflow-y: auto;
  position: absolute;
  right: 0;
}

.app-bar-mobile {
  width: 100%;
}

.app-bar-web {
  width: calc(100% - 320px);
}

.banner-title {
  display: flex;
  align-items: center;
  justify-content: end;
}

.logo-ton {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 0.5em;
}

.banner-pledge-box {
  width: 100%;
  height: 59px;
  line-height: 59px;
  background-color: rgba(0, 136, 204, 0.15); // #cfdaff
  color: #0088cc;
  font-weight: bold;
  font-size: 1.2em;
  text-align: right;
  padding-right: 2em;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(255, 167, 11, 0.15);
}

.banner-title-arrow {
  margin-left: 0.2em;
}
</style>
